





import { Component } from 'vue-property-decorator'

import { Modal, Select, PersonClosureReason, closureReasons, ApiError } from '@focus/components'
import BaseComponent from '@/plugins/mixins'

@Component({
  components: { Modal, Select }
})
export default class LogoutModal extends BaseComponent {
  isOpen = false
  color = 'error'
  icon = 'error'
  text = 'Are you sure you want to close your account?'
  subtext = 'Your account will be closed. You will not be able to log back in and you will not be able to participate in any new jobs. You can request your account to be reinstated by contacting us'
  confirmText = 'Confirm'

  closureReasons = closureReasons

  reason: PersonClosureReason | null = null

  close () {
    this.isOpen = false
  }

  open () {
    this.isOpen = true
  }

  async confirm () {
    try {
      await this.$store.dispatch('auth/closeAccount', { reason: this.reason })
      this.$emit('confirm')
      this.close()
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }
}
