





















































import { Component } from 'vue-property-decorator'
import { ApiError } from '@focus/components'
import BaseComponent from '@/plugins/mixins'
import UnsubscribeConfirmModal from '../components/UnsubscribeConfirmModal.vue'

@Component({
  components: { UnsubscribeConfirmModal }
})
export default class UnsubscribePage extends BaseComponent {
  status: 'pending' | 'success' | 'failed' = 'pending'

  loading = false

  get identity () {
    return this.$route.params.identity
  }

  get signature () {
    return this.$route.params.signature
  }

  get unsubscribeModal () {
    return this.$refs.unsubscribeModal as UnsubscribeConfirmModal
  }

  async mounted () {
    this.unsubscribeModal.open()
  }

  async unsubscribe () {
    this.loading = true
    try {
      await this.$store.dispatch('profile/unsubscribe', { identity: this.identity, signature: this.signature })
      this.loading = false
      this.status = 'success'
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    } finally {
      this.loading = false
    }
  }
}
