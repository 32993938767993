



import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { ErrorDetails, Modal } from '@focus/components'
import { RespondentProfileData } from '@/domains/auth'

@Component({
  components: { Modal }
})
export default class AccountUpgradeSuccessModal extends Vue {
  private show = false

  private error: ErrorDetails<RespondentProfileData> | null = null

  get errorMessage () {
    return this.error?.message
  }

  public open (data: ErrorDetails<RespondentProfileData>) {
    this.error = data
    this.show = true
  }

  public close () {
    this.show = false
  }
}
