















































import { Component } from 'vue-property-decorator'
import {
  Button,
  Card,
  CardTitle,
  Datepicker,
  Form,
  ReadField,
  Select,
  TextField,
  State,
  verboseState,
  states,
  yesNo,
  genders,
  isPhoneNumber,
  ApiError
} from '@focus/components'

import Header from '@/components/Header.vue'
import BaseComponent from '@/plugins/mixins'

import { RespondentProfile } from '../profile.types'

@Component({
  components: { Button, Card, CardTitle, Datepicker, Form, ReadField, Select, TextField, Header }
})
export default class EditProfilePage extends BaseComponent {
  profile: Partial<RespondentProfile> = {}

  get states () {
    return states
  }

  get yesNo () {
    return yesNo
  }

  get genders () {
    return genders
  }

  get form (): Form {
    return this.$refs.form as Form
  }

  async mounted () {
    try {
      const data = await this.$store.dispatch('profile/my-profile')
      if (data) {
        data.dateOfBirth = new Date(data.dateOfBirth)
      }
      this.profile = data || {}
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  displayState (state: State) {
    return verboseState(state)
  }

  phoneRule (val: string) {
    return !val || isPhoneNumber(val)
  }

  async saveDetails () {
    if (this.form.validate()) {
      try {
        await this.$store.dispatch('profile/update-profile', this.profile)
        this.$router.push({ name: 'my-details' })
      } catch (error) {
        if (error instanceof ApiError) {
          await this.showError(error)
        } else {
          throw error
        }
      }
    }
  }
}
