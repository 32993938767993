


















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Button, Card, CardTitle, Datepicker, Form, FormErrors, Select, TextField, formatValidationError, genders, isPhoneNumber, postcodeRule, preferredContacts, states, yesNo, ErrorDetails } from '@focus/components'

import { RespondentProfileData } from '@/domains/auth'
import Header from '@/components/Header.vue'

import AccountUpgradeSuccessModal from '../components/AccountUpgradeSuccessModal.vue'
import AccountUpgradeFailedModal from '../components/AccountUpgradeFailedModal.vue'

@Component({
  components: { Button, Card, CardTitle, Header, Datepicker, Select, TextField, Form, AccountUpgradeSuccessModal, AccountUpgradeFailedModal }
})
export default class UpgradeGuestAccountPage extends Vue {
  get isMobile () {
    return this.$vuetify.breakpoint.xs
  }

  get error (): ErrorDetails<RespondentProfileData> | null {
    const error = this.$store.state.profile.error
    if (error?.code === 'VALIDATION_FAILED') {
      return null
    }

    return error
  }

  get errors (): FormErrors<RespondentProfileData> {
    const error = this.$store.state.profile.error
    if (error?.code === 'VALIDATION_FAILED') {
      return formatValidationError(error)
    }
    return {}
  }

  get yesNo () {
    return yesNo
  }

  get preferredContacts () {
    return preferredContacts
  }

  get states () {
    return states
  }

  get genders () {
    return genders
  }

  get form () {
    return this.$refs.form as Form
  }

  get upgradeSuccessModal () {
    return this.$refs.upgradeSuccessModal as AccountUpgradeSuccessModal
  }

  get upgradeFailedModal () {
    return this.$refs.upgradeFailedModal as AccountUpgradeFailedModal
  }

  private formData: Partial<RespondentProfileData> = {}

  phoneRule (val: string) {
    return !val || isPhoneNumber(val)
  }

  postcodeRule (val: string) {
    return postcodeRule(val)
  }

  async submit () {
    if (this.form.validate()) {
      try {
        await this.$store.dispatch('profile/upgrade', this.formData)
        this.upgradeSuccessModal.open()
      } catch (error) {
        if (this.error) {
          this.upgradeFailedModal.open(this.error)
        }
      }
    }
  }
}
