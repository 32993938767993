



import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { Modal } from '@focus/components'

@Component({
  components: { Modal }
})
export default class UnsubscribeConfirmModal extends Vue {
  private show = false

  public open () {
    this.show = true
  }

  public close () {
    this.show = false
  }

  private confirm () {
    this.$emit('confirm')
    this.close()
  }
}
