



import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { Modal } from '@focus/components'

@Component({
  components: { Modal }
})
export default class AccountUpgradeSuccessModal extends Vue {
  private show = false

  public open () {
    this.show = true
  }

  public close () {
    this.show = false
  }
}
