































































import { Component } from 'vue-property-decorator'
import {
  Banner,
  Button,
  Card,
  CardTitle,
  ReadField,
  State,
  verboseState,
  date,
  verbosePreferredContact,
  PreferredContactMethod,
  capitalize,
  ApiError
} from '@focus/components'

import Header from '@/components/Header.vue'

import CloseAccountModal from '../../auth/components/CloseAccountModal.vue'
import { RespondentProfile } from '../profile.types'
import BaseComponent from '@/plugins/mixins'

@Component({
  components: { Banner, Button, Card, CardTitle, CloseAccountModal, Header, ReadField }
})
export default class MyProfilePage extends BaseComponent {
  profile: RespondentProfile | null = null

  get profileDetails (): Partial<RespondentProfile> {
    return this.profile || {}
  }

  get address () {
    return `${this.profileDetails.addressOne || ''} ${this.profileDetails.addressTwo || ''}`
  }

  get error () {
    const error = { ...this.$store.state.profile.error }
    if (error.code === 'NOT_FOUND') {
      return { ...error, message: 'Unable to locate your details, the record either doesn\'t exist or has been removed.' }
    } else {
      return error
    }
  }

  get closeAccountModal () {
    return this.$refs.closeAccountModal as CloseAccountModal
  }

  async mounted () {
    try {
      this.profile = await this.$store.dispatch('profile/my-profile')
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  backToLogin () {
    this.$router.push({ name: 'login' })
  }

  displayState (state?: State) {
    return verboseState(state)
  }

  displayDate (value?: Date) {
    return date(value)
  }

  verboseContactMethod (value?: PreferredContactMethod) {
    return verbosePreferredContact(value)
  }

  capitalize (text?: string) {
    return capitalize(text)
  }
}
