









import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class Header extends Vue {
  get isMobile () {
    return this.$vuetify.breakpoint.smAndDown
  }
}
